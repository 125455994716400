/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };
  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';
      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');
      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });
      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };
  // Load Events
  $(document).ready(UTIL.loadEvents);
  
})(jQuery); // Fully reference jQuery after this point.


//colors menu dynamicly
jQuery(".dropdown-menu >li").each(function(){
    a = jQuery(this).children().attr("color");
    jQuery(this).children().css("color", a);
});

jQuery(document).ready(function(){
	function hexToRgb(hex) {
	    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	    return result ? {
	        r: parseInt(result[1], 16),
	        g: parseInt(result[2], 16),
	        b: parseInt(result[3], 16)
	    } : null;
	}
jQuery(".gfield_error input").focus(function(){
	jQuery(this).parent().parent().focus();
});
	/*********CHANGE COLOR OF ALL LINKS AND BORDER*********************/
	//chnges hex-color to rgba for transparency
	var pageColor = jQuery(".nav li.active a").attr("color");
	var r = hexToRgb(pageColor).r;
	var g = hexToRgb(pageColor).g;
	var b = hexToRgb(pageColor).b;
	jQuery(".wrap.container a").css("color", pageColor);
	jQuery(".overlay.border").css("border", "50px solid rgba("+r+","+g+","+b+", 0.5)");
		//sets an offset to the end-text if an editor is used on the page
		if(jQuery(".col-sm-12").children().hasClass("content-box")){
			jQuery(".end-text-container").addClass("text-padding");
		}
	
	/********ANGEBOT COLOR DOT & SUBMENÜ ADJUSTMENT*********/
	jQuery('li.dropdown a[data-toggle="dropdown"]').hover(function(){
		var itemColor = (jQuery(this).next().children().children().first().attr("style"));
		jQuery(this).children().attr("style", itemColor);
	});
	jQuery('li.dropdown a[data-toggle="dropdown"]').mouseleave(function(){
		jQuery(this).children().css("color", "");
	});
	
	
	/********KONTAKT***********/
	//changes back button
	var clicked = false;
	jQuery(".back-btn").click(function(){
		clicked = false;
	});
	jQuery(".submit-overlay").click(function(){
		if(clicked === true){
			jQuery("#gform_submit_button_1").trigger('click');
			setTimeout(function(){
				if(jQuery("span").hasClass("valid")){
					jQuery(".back-btn").text("ZURÜCK ZUR KONTAKTSEITE");
				}else{
					return;
				}
			},900);
		}else{
			jQuery(".form-btn").trigger('click');
			clicked = true;
		}
	});
	//hide or show the contact form
    jQuery(".form-btn").click(function(){
        jQuery("main").addClass("form-active");
        jQuery(".form.closed").removeClass("closed");
        jQuery(".back-btn.hidden").removeClass("hidden");
        jQuery(".page-title, .contact-container").hide();
        jQuery(this).hide();
    });
    jQuery(".back-btn, .back-btn:before").click(function(){
        jQuery("main").removeClass("form-active");
        jQuery(".form").addClass("closed");
        jQuery(".back-btn").addClass("hidden");
        jQuery(".page-title, .contact-container").show();
        jQuery(".form-btn").show();
    });
    /********KONTAKT***********/
	
	//sets elements to full width
	function fullwidth(){
		var W = jQuery(".container-fluid").outerWidth();
		jQuery(".full-width-img").outerWidth(W);
		jQuery(".dropdown-menu").outerWidth(W);
	}
	
	//positions full-width-img		
    function fullWidthImg(e){
	    if(jQuery(window).width() > 768){
		    if(jQuery("body *").hasClass("full-width-img")){
			    var windowW = jQuery(window).width();		
		        var containW = jQuery(".refrence.container").width();		
		        var diffe = (windowW - containW)/2 - 30;	
		        jQuery(".full-width-img").outerWidth(windowW);
		        jQuery(".full-width-img").css("margin-left", ((diffe + 45) * -1));	
		    }
		}else{
			jQuery(".full-width-img").css("margin-left","");
		}	
    }
    
    //function for dynamic focus toggle on mobile
    var clickcount = 0;
    var last;
    jQuery(".step-container").on('click', function toggleBox(){
	    clickcount ++;
	    if(clickcount === 1){
		    last = this;
	    }
	    jQuery(this).attr("clicked", clickcount);
	    
	    if(last !== this){
		    clickcount = 1;
		    jQuery(last).attr("clicked", 0);
		    jQuery(this).attr("clicked", 1);
		    last = this;
	    }else{
		    last = this;
	    }
	    if(jQuery(this).attr("clicked") % 2 === 0){
		   jQuery(this).attr("tabindex",-1).blur();
	    }else{
		    jQuery(this).attr("tabindex",-1).focus();
	    }
    });
    
    // Change color "Angebot" to the hover subnavigationpoint
    jQuery('.dropdown-menu > li > a').hover(function () {
        jQuery('.dropdown > a > span.h-color-dot').css('color', jQuery(this).css('color'));
    
    }, function () {
        jQuery('.dropdown > a > span.h-color-dot').css('color', '');
    });
    
	//resizes img in akkordeon so they dont lose proportion
	function accordion_resize() {
		jQuery(".panel").each(function(e){
			//images original sizes
			var ori_height = parseInt(jQuery(this).children().first().attr("height"));
			var ori_width = parseInt(jQuery(this).children().first().attr("width"));
			var ori_prop = ori_width / ori_height;
			var mob_tooBig = false;
			var desk_tooBig = false;
			var once = true;
			
			//the heights to apply
			var desk_height = 764;
			var mob_height = 358;
			var offTop;

			/*Center vertical*/
			if(once){
				if(jQuery(window).width() > 768 ){//desk
					if(jQuery(".panel.panel-default").width() / ori_prop > desk_height){
						offTop = ((jQuery(".panel.panel-default").width() / ori_prop) - desk_height) / 2;
						jQuery(this).children().first().css("top", (offTop * -1));
					}else{
						jQuery(this).children().first().css("top", "");
					}
				}else{//mobile
					if(jQuery(".panel.panel-default").width() / ori_prop > mob_height){
						offTop = ((jQuery(".panel.panel-default").width() / ori_prop) - mob_height) / 2;
						jQuery(this).children().first().css("top", (offTop * -1));
					}else{
						jQuery(this).children().first().css("top", "");
					}
				}
				once = false;
			}
			
			jQuery(this).children().first().height(jQuery(".panel.panel-default").width() / ori_prop);
			
			if(jQuery(".panel.panel-default").width() / ori_prop < desk_height){//requires adjustment
				if(jQuery(window).width() < 769){//mobile
					
					jQuery(this).children().first().width(mob_height * ori_prop);
					if(jQuery(".panel.panel-default").width() < mob_height * ori_prop){
						mob_tooBig = true;
					}else{
						mob_tooBig = false;
					}
				}else{//desktop
					jQuery(this).children().first().width(desk_height * ori_prop);
					if(jQuery(".panel.panel-default").width() < desk_height * ori_prop){
						desk_tooBig = true;
					}else{
						desk_tooBig = false;
					}
				}
				if(mob_tooBig || desk_tooBig){
					if(mob_tooBig){
						jQuery(this).children().first().css("margin-left", (mob_height * ori_prop - jQuery(".panel.panel-default").width()) / 2 * -1);
					}else {
						jQuery(this).children().first().css("margin-left", (desk_height * ori_prop - jQuery(".panel.panel-default").width()) / 2 * -1);
					}
					
				}else{//undo adjustments
					jQuery(this).children().first().css("margin-left", 0);
				}
			}else{
				jQuery(this).children().first().width();
				jQuery(this).children().first().css("margin-left", 0);
			}
		});
	}
	
    //functions for resizing
    jQuery(window).resize(function(){
	    fullwidth();
		fullWidthImg();
		accordion_resize();
    });
	fullwidth();
	fullWidthImg();
    accordion_resize();
});

//changes height of vw values when not supported
jQuery(document).ready(function(){
	if(jQuery(".container-fluid").height() < 5){
		jQuery(".page-template-template-default .container-fluid").height(jQuery(window).height() - 93);
		jQuery(".home .container-fluid, .page-template-template-contact .container-fluid").height(jQuery(window).height() - 98);
	}
});

// Entwicklung Page, toggle accordion
jQuery(document).ready(function(){
	
	jQuery(".panel.panel-default").click(function(){
		
		if(jQuery(this).hasClass("accordion-open")){
			jQuery(this).removeClass("accordion-open");
			jQuery(this).siblings().removeClass("small-accordion");
			
		}else if(jQuery(this).hasClass("small-accordion")){
			jQuery(this).removeClass("small-accordion");
			jQuery(this).addClass("accordion-open");
			jQuery(this).siblings().removeClass("accordion-open");
			jQuery(this).siblings().addClass("small-accordion");
			
		}else{
			jQuery(this).addClass("accordion-open");
			jQuery(this).siblings().addClass("small-accordion");
		}
	});      
});

//Grayscale on hover accordion
jQuery(".panel").mouseenter(function(e){
	if(jQuery(this).hasClass("grayscale")){
		var temp;
		jQuery(this).children().first().addClass('grayscale-off');
		if(jQuery(this).hasClass('accordion-open')){
			temp = e;
			jQuery(temp).children().first().removeClass('grayscale-off');
			jQuery(temp).click(function(){
				jQuery(temp).children().first().addClass('grayscale-off');
			});
		}
	}
	
});

//animated scroll
jQuery(".entwicklung .panel-default:not(.accordion-open) .panel-title > a").click(function(){
	var curr_object = jQuery(this).parent().parent().parent().parent().parent();
	setTimeout(function(){
		offHeight = curr_object.offset().top;
		jQuery("html, body").animate({scrollTop:offHeight}, '250');
	}, 300);
});